import React, { useContext, useEffect, useState } from "react"
import { graphql, PageProps } from "gatsby"
import { Layout } from "../components/atoms/Layout"
import GenericHero from "../components/organisms/Accessory/Hero/GenericHero"
import { SEO } from "../components/atoms/SEO"
import {
  ContentSlider,
  SeriesSelection,
} from "../components/organisms/Homepage"
import tw from "twin.macro"
import { Link } from "../components/atoms/Link"
import { Image } from "../components/atoms/Image"
import {
  Brochure,
  ImageBlocks,
  ToyotaCare,
} from "../components/organisms/Series"
import { DisclaimersContext } from "../contexts/Disclaimers"
import {
  setDisclaimers,
  toggleDisclaimersModal,
} from "../contexts/Disclaimers/actions"
import { parseDisclaimerBlocks } from "../helpers"
import useTealiumView from "../hooks/Tealium/useTealiumView"
import TactVideoSection from "../components/organisms/Tact/TactVideoSection"
import FAQ from "../components/molecules/FAQ/FAQ"
import { Gallery } from "../components/organisms/Series"
import useTealiumEvent from "../hooks/Tealium/useTealiumEvent"
import { ImageBlocksWithDescription } from "../components/molecules/ImageBlocksWithDescription"
import { VideoFeatureTabsSection } from "../components/molecules/VideoFeatureTabsSection"
import VideoWithText from "../components/molecules/VideoWithText/VideoWithText"
import TabbedImageModule from "../components/molecules/TabbedImageModule/TabbedImageModule"

const SegmentPage: React.FC<PageProps> = ({ data, location }: any) => {
  const { page } = data
  const [state, dispatch] = useContext(DisclaimersContext)

  // Tealium
  const { handleTealView } = useTealiumView()

  const familyRef = page.content.filter(
    (element: { _type: string }) => element._type === "seriesSelection"
  )[0].familyRef[0]
  // Hybrids segment includes multiple hybrid categories. This assigns them the generic "hybrid".
  const familySegment = familyRef?.slug?.current?.includes("hybrid")
    ? "hybrids"
    : familyRef?.slug?.current
  useEffect(() => {
    handleTealView({
      tealium_event: `${familySegment}_lander`,
      page_name: familySegment.replace("_", " "),
      page_type: "segment",
      view: "view",
    })
  }, [])
  // End Tealium

  return (
    <Layout>
      <SEO
        url={location.href}
        title={page.title}
        keywords={page.seo?.keywords}
        schema={page.seo?.schema}
        description={page.seo?.description}
        canonicalPath={page.seo?.canonicalPath}
      />
      <SanitySections data={page} familySegment={familySegment} />
    </Layout>
  )
}
const SanitySections = ({ data, familySegment }: any): JSX.Element => {
  const [state, dispatch] = useContext(DisclaimersContext)
  const { trackTealEvent } = useTealiumEvent()
  return (
    <>
      {data?.content?.map((section: any) => {
        switch (section?._type) {
          case "genericHero":
            return (
              <GenericHero
                {...section}
                showGradient={false}
                textInverted={true}
                fromHybridSegment={familySegment === "hybrids"}
                fromSegment={true}
              />
            )
          case "imageWithText":
            return <ToyotaCare {...section} />
          case "brochure":
            return <Brochure {...section} />
          case "seriesSelection":
            return (
              <SeriesSelection
                {...section}
                analyticsIdArrows="vehicle:vehicle"
                fromHybridSegment={familySegment === "hybrids"}
              />
            )
          case "videoSection":
            return <TactVideoSection {...section} />
          case "tabbedImageModule":
            return <TabbedImageModule {...section} />
          case "gallery":
            return (
              <Gallery
                {...section}
                fromHybridSegment={true}
                showTitle={false}
              />
            )
          case "faqSection":
            return <FAQ {...section} expanded showTitle={true} />
          case "imageBlocksWithDescriptionSection":
            return (
              <ImageBlocksWithDescription
                section={section}
                analyticsIdArrows="explore:explore"
              />
            )
          case "videoBlocks":
            return (
              <>
                {section?.videoBlockHeader && (
                  <h2
                    css={[
                      tw`text-2xl text-center font-light max-w-3xl mx-auto mb-6`,
                      tw`lg:(text-4xl pt-20)`,
                      "letter-spacing: 7.72px",
                    ]}
                  >
                    {section?.videoBlockHeader}
                  </h2>
                )}
                {section?.videoBlockHeading && (
                  <p
                    css={[
                      tw`text-base mx-8 mb-20 font-book md:(max-w-5xl mx-auto mb-8 text-center)`,
                    ]}
                  >
                    {section?.videoBlockHeading}
                  </p>
                )}
                <section css={[tw`w-full h-[32rem] bg-black z-40`]}>
                  <ContentSlider
                    sectionTitle={section?.sectionTitle}
                    analyticsId="video:video"
                    sectionSlug={section?.sectionSlug}
                    css={[tw`h-[32rem] overflow-y-hidden`]}
                  >
                    {section?.videoBlock?.map(
                      ({ heading, video_s3 }, i: number) => {
                        const [active, setActive] = useState(false)
                        const isBrowser = typeof window !== "undefined"
                        const videoDiv =
                          isBrowser &&
                          document?.getElementById(`${heading}-${i}`)
                        const [mobileView, setMobileView] = useState(null)

                        useEffect(() => {
                          const mediaQuery = window.matchMedia(
                            "(max-width: 1024px)"
                          )
                          setMobileView(mediaQuery.matches || false)
                          mediaQuery.addEventListener("change", e => {
                            const mobileViewOn = e.matches
                            setMobileView(mobileViewOn)
                          })
                        }, [])

                        // tealium
                        const tealVideoEvent = (
                          action: string,
                          time?: string
                        ) => {
                          const percentWatched = () => {
                            var seconds = videoDiv?.duration % 60
                            const percentage = Math.round(
                              (videoDiv.currentTime / seconds) * 100
                            )
                            // (Put the minutes and seconds in the display)

                            return percentage
                          }

                          const timeOnPause = `${percentWatched()}:${Math.round(
                            videoDiv?.currentTime
                          )}`

                          trackTealEvent({
                            tealium_event: "video_event",
                            video_type: `${`${heading}-${i + 1}`}|${action}|${
                              time ? time : timeOnPause
                            }`,
                          })
                        }

                        return (
                          <div css={[tw`relative h-[32rem]`]}>
                            <video
                              id={`${heading}-${i}`}
                              controls={active}
                              title={heading}
                              src={`${process.env.GATSBY_ASSET_URL}${video_s3?.asset?.s3?.key}`}
                              onClick={() => {
                                setActive(true),
                                  mobileView && !active
                                    ? videoDiv?.play()
                                    : null
                              }}
                              onPlay={() => {
                                //only fire on initial play
                                videoDiv?.currentTime == 0 &&
                                  tealVideoEvent("start", "0:0")
                              }}
                              onPause={() => {
                                //don't fire on end of video
                                videoDiv?.currentTime !== videoDiv?.duration &&
                                  tealVideoEvent("pause")
                              }}
                              onEnded={() => {
                                tealVideoEvent(
                                  "ended",
                                  `100:${Math.round(videoDiv?.duration)}`
                                )
                              }}
                              poster={
                                section?.videoBlock[i]?.posterImage?.asset?.url
                              }
                              css={[
                                !active
                                  ? tw` object-cover w-full h-full`
                                  : tw`object-contain mx-auto w-auto h-[32rem]`,
                              ]}
                            ></video>
                            <div
                              css={[
                                !active
                                  ? tw`block absolute top-1/2 left-1/2 z-50 text-red-400 translate-x-[-50%] translate-y-[-50%] mx-auto pointer-events-none`
                                  : tw`hidden`,
                              ]}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                className="bi bi-play"
                                viewBox="0 0 16 16"
                                width={"4em"}
                                height={"4em"}
                              >
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z" />
                              </svg>
                            </div>
                          </div>
                        )
                      }
                    )}
                  </ContentSlider>
                </section>
              </>
            )
          case "imageBlocks":
            const imageBlocksData = section?.callouts
            return (
              <ImageBlocks
                imageBlocks={imageBlocksData}
                _key={"Image Blocks"}
                sectionSlug={{
                  current: "",
                }}
              />
            )
          case "videoFeatureTabsSection":
            return <VideoFeatureTabsSection {...section} />
          case "videoWithText":
            return <VideoWithText {...section} />
        }
      })}
    </>
  )
}

export const query = graphql`
  query SegmentPageQuery($id: String) {
    page: sanityPageSegment(id: { eq: $id }) {
      id
      language
      title
      seo {
        keywords
        schema
        description
        canonicalPath
      }
      content {
        ...GenericHeroData
        ...ImageBlocksData
        ...ToyotaCareData
        ...SeriesSelectionData
        ...BrochureData
        ...VideoSectionData
        ...TabbedImageModuleData
        ...VideoBlocksDataWithMedia
        ...GallerySection
        ...FaqSectionData
        ...ImageBlocksWithDescriptionData
        ...VideoFeatureTabsSection
        ...VideoWithTextSection
      }
      disclaimers {
        code
        disclaimer
      }
    }
  }
`

export default SegmentPage
