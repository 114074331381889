import React, { useContext, useEffect, useRef, useState } from "react"
import { VideoFeatureTabProps } from "./VideoFeatureTabsSection.d"
import tw from "twin.macro"
import { DisclaimersContext } from "../../../contexts/Disclaimers"
import {
  setActiveDisclaimer,
  toggleDisclaimersModal,
} from "../../../contexts/Disclaimers/actions"
import { parseDisclaimerBlocks } from "../../../helpers"
import VideoS3 from "../VideoSection/VideoS3"
import { Image } from "../../atoms/Image"
import Play from "../../atoms/Icon/Play"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"

const VideoFeatureTab: React.FC<VideoFeatureTabProps> = ({
  name,
  description,
  posterImage,
  video_s3,
  active,
  id,
}) => {
  const [state, dispatch] = useContext(DisclaimersContext)
  const videoS3El = useRef(null)
  const { trackTealEvent } = useTealiumEvent()
  const [showImageOverlay, setShowImageOverlay] = useState(true)
  const [videoStatus, setVideoStatus] = useState("")

  useEffect(() => {
    if (!active && videoStatus === "play") {
      videoS3El?.current?.pause()
    }
  }, [active])

  // Tealium for video events using S3 hosted videos
  const percentWatched = () => {
    const percentage = Math.round(
      (videoS3El?.current.currentTime / videoS3El?.current?.duration) * 100
    )

    return percentage
  }
  const tealVideoEvent = (action: string, time?: string) => {
    const timeOnPause = `${percentWatched()}:${Math.round(
      videoS3El?.current?.currentTime
    )}`

    trackTealEvent({
      tealium_event: "video_event",
      video_type: `${`${name}-${video_s3?.asset?._id}`}|${action}|${
        time ? time : timeOnPause
      }`,
    })
  }

  return (
    <div
      css={[
        tw`hidden absolute w-full mx-auto flex-wrap gap-10 p-6 pb-12 rounded-xl bg-white z-0`,
        tw`md:(-top-2 gap-14 p-14 mx-auto)`,
        tw`lg:(gap-32 grid grid-cols-2 grid-rows-1 grid-flow-row mb-10)`,
        active && tw`flex z-10 shadow-4`,
      ]}
      id={id}
    >
      <div
        css={[
          tw`text-xl text-center flex flex-col gap-6 justify-center leading-8`,
          tw`lg:(col-span-1 text-left)`,
        ]}
      >
        {name && <h3 css={[tw`text-2xl font-bold`]}>{name}</h3>}
        {description &&
          parseDisclaimerBlocks(description, (code: string) => {
            dispatch(setActiveDisclaimer(code))
            dispatch(toggleDisclaimersModal())
          })}
      </div>

      {/* Video Section */}
      <div
        css={[
          tw`items-center col-span-2 mt-2 cursor-pointer w-full relative`,
          tw`lg:(col-span-1 mt-0)`,
        ]}
      >
        <div
          onClick={() => {
            setShowImageOverlay(false)
            videoS3El?.current?.play()
            setVideoStatus("play")
          }}
        >
          <video
            ref={videoS3El}
            id={video_s3?.asset?._id}
            controls={true}
            title={name}
            src={`${process.env.GATSBY_ASSET_URL}${video_s3?.asset?.s3?.key}`}
            onPlay={() => {
              //only fire on initial play
              videoS3El?.current?.currentTime == 0 &&
                tealVideoEvent("start", "0:0")
              setVideoStatus("play")
            }}
            onPause={() => {
              //don't fire on end of video
              videoS3El?.current?.currentTime !==
                videoS3El?.current?.duration && tealVideoEvent("pause")
              videoS3El?.current?.currentTime !==
                videoS3El?.current?.duration && setVideoStatus("pause")
            }}
            onEnded={() => {
              tealVideoEvent(
                "ended",
                `100:${Math.round(videoS3El?.current?.duration)}`
              )
              setVideoStatus("ended")
            }}
            css={[
              tw`rounded-2xl object-contain mx-auto w-full h-[18em] p-0 bg-black`,
              tw`md:(w-full)`,
              tw`lg:(h-[24rem] w-auto)`,
              "aspect-ratio: 1/1",
            ]}
          ></video>
          {posterImage && showImageOverlay && (
            <div>
              <Image
                imageData={posterImage?.image}
                css={[
                  tw`absolute top-0 left-0 rounded-2xl object-cover mx-auto w-full h-[18rem] p-0`,
                  tw`md:(w-full)`,
                  tw`lg:(h-[24rem] w-full)`,
                  "aspect-ratio: 1/1",
                ]}
              />
              <Play
                css={tw`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2`}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default VideoFeatureTab
