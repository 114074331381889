import React, { useContext, useEffect, useRef, useState } from "react"
import { bgImageWithGeoLocation, GenericHeroProps } from "./GenericHero.d"
import tw from "twin.macro"
import { ButtonLink } from "../../../atoms/Button"
import { Image } from "../../../atoms/Image"
import { CTA } from "../../../../global"
import { getCTALinkValue, parseDisclaimerBlocks } from "../../../../helpers"
import { toggleDisclaimersModal } from "../../../../contexts/Disclaimers/actions"
import { DisclaimersContext } from "../../../../contexts/Disclaimers"
import { css } from "@emotion/react"
import { LanguageContext } from "../../../../contexts/Language"

/**
 *
 * @author Cody
 * @summary
 *
 *
 */

const GenericHero: React.FC<GenericHeroProps> = ({
  backgroundImages,
  backgroundImagesMobile,
  ctas,
  heroLogo,
  heading,
  subHeading,
  textInverted = false,
  fromHybridSegment = false,
  fromSegment = false,
  fromAccessory = false,
}) => {
  const [state, dispatch] = useContext(DisclaimersContext)
  const { language } = useContext(LanguageContext)
  /* User geolocation placeholder - Until we know how we're going to actually implement it */
  const userLocation = "US"
  /* Pull in background image that matches user geo location, if none match use 'all' */
  const bgImageMobile: bgImageWithGeoLocation = backgroundImagesMobile?.find(
    location =>
      location?.geolocation === userLocation || location?.geolocation === "all"
  )
  const bgImage: bgImageWithGeoLocation = backgroundImages?.find(
    location =>
      location?.geolocation === userLocation || location?.geolocation === "all"
  )
  const contentRef = useRef(null)
  const [mobileHeightHybridLander, setMobileHeightHybridLander] = useState(null)
  useEffect(() => {
    setMobileHeightHybridLander(contentRef.current?.clientHeight + 150)
  }, [])

  return (
    <section
      css={[
        tw`grid h-[530px] w-full relative overflow-hidden`,
        tw`sm:(h-[510px])`,
        tw`lg:(h-full)`,
        fromSegment && tw`h-[600px] sm:(h-[640px]) desktop-hd:(h-[760px])`,
        fromHybridSegment &&
          mobileHeightHybridLander &&
          fromHybridSegment &&
          tw`lg:h-[370px]`,
      ]}
      aria-label="Hero Section"
    >
      {/* BACKGROUND IMAGE */}
      {bgImage && (
        <Image
          imageData={bgImage.image.image}
          css={[
            tw`object-center h-full w-full object-cover contrast-150 z-0 relative`,
            tw`lg:(block object-[0% 80%] w-full max-h-[640px]) desktop-hd:(max-h-[760px])`,
            fromHybridSegment && tw`h-[150px] lg:h-full`,
            // if there is a bgImageMobile, hide the bgImage on mobile
            bgImageMobile && tw`hidden lg:(block)`,
            fromAccessory &&
              tw`h-[230px] md:(h-[300px]) lg:(h-full object-[0% 70%])`,
          ]}
        />
      )}
      {bgImageMobile && (
        <Image
          imageData={bgImageMobile.image.image}
          css={[
            tw`object-center h-full w-full object-cover contrast-150 z-0 relative`,
            fromSegment && tw`h-[250px] lg:h-full sm:(h-[400px])`,
            fromHybridSegment && tw`h-[250px] lg:h-full`,
            tw`lg:(hidden)`,
          ]}
        />
      )}
      {/* HERO SECTION */}
      <section
        css={[
          tw`absolute top-0 w-full mx-auto z-20`,
          tw`md:(auto-rows-min pb-0 pt-0 px-12)`,
          fromHybridSegment &&
            tw`flex mt-[180px] max-w-7xl mx-auto left-1/2 transform -translate-x-1/2 sm:(mt-[330px]) lg:(mt-0 ml-6 h-[370px]) 2xl:(mx-0)`,
        ]}
      >
        <div
          css={[
            tw`p-6 text-black mt-[28%] max-w-full`,
            tw`md:(bg-gray-50 mt-[33%] max-w-full bg-opacity-90 rounded-2xl)`,
            tw`lg:(my-6 max-w-[520px])`,
            tw`xl:(my-20)`,
            textInverted &&
              tw`md:(bg-transparent bg-opacity-90) lg:(bg-gray-50 bg-opacity-90) xl:(bg-gray-50 bg-opacity-90)`,
            fromSegment && tw`mt-[240px] sm:(mt-[390px]) lg:(mt-[100px])`,
            fromHybridSegment &&
              tw`self-center p-8 mt-0 my-0 md:my-0 lg:my-0 xl:my-0`,
            language === "es" && tw`lg:(max-w-[600px])`,
            fromAccessory &&
              tw`mt-56 md:(mt-80 bg-transparent) lg:(mt-20 bg-gray-50)`,
          ]}
          ref={contentRef}
        >
          {fromHybridSegment && heroLogo && (
            <Image
              imageData={heroLogo.image}
              css={[tw`max-w-[12rem] mx-auto`]}
            />
          )}
          <h1
            css={[
              tw`text-black text-3xl mb-2`,
              tw`md:(text-black text-4xl)`,
              fromHybridSegment && tw`font-semibold text-3xl text-center mt-4 `,
              textInverted && tw`text-black`,
            ]}
          >
            {" "}
            {parseDisclaimerBlocks(heading, selection =>
              dispatch(toggleDisclaimersModal(selection))
            )}
          </h1>
          <h3
            css={[
              tw`text-black text-lg col-span-2`,
              tw`md:(text-black col-span-1 text-xl)`,
              fromHybridSegment && tw`text-center md:(text-lg)`,
              textInverted && tw`text-black`,
            ]}
          >
            {parseDisclaimerBlocks(subHeading, selection =>
              dispatch(toggleDisclaimersModal(selection))
            )}
          </h3>
        </div>
        {/* CTAs */}
        {ctas.length > 0 ? (
          <div
            css={[
              tw`col-span-2 col-start-1 flex mt-4 justify-center row-start-6 items-center gap-2`,
              tw`md:(col-span-1 row-start-3 row-end-4 justify-start items-start my-0 ml-6 mt-4)`,
            ]}
          >
            {ctas?.map((cta: CTA) => {
              return (
                <ButtonLink
                  {...{ [cta?.buttonType]: true }}
                  to={getCTALinkValue(cta)}
                  target={cta?.linkType === "external" ? "_blank" : "_self"}
                  css={[
                    tw`w-full col-span-1 px-3 text-xs h-full flex justify-center items-center`,
                    tw`md:w-auto`,
                  ]}
                  key={cta?.title}
                >
                  {cta?.title}
                </ButtonLink>
              )
            })}
          </div>
        ) : null}
      </section>
    </section>
  )
}

export default GenericHero
