import React, { useContext, useEffect, useState } from "react"
import {
  VideoFeatureTabType,
  VideoFeatureTabsSectionProps,
} from "./VideoFeatureTabsSection.d"
import tw from "twin.macro"
import Icon from "../../atoms/Icon"
import VideoFeatureTab from "./VideoFeatureTab"
import { parseDisclaimerBlocks, windowExists } from "../../../helpers"
import { toggleDisclaimersModal } from "../../../contexts/Disclaimers/actions"
import { DisclaimersContext } from "../../../contexts/Disclaimers"

const VideoFeatureTabsSection: React.FC<VideoFeatureTabsSectionProps> = ({
  sectionTitle,
  subTitle,
  featureTabs,
}) => {
  const [activeFeatureIndex, setActiveFeatureIndex] = useState(0)
  const [state, dispatch] = useContext(DisclaimersContext)
  const [activeFeatureHeight, setActiveFeatureHeight] = useState<number>()

  useEffect(() => {
    if (!windowExists) return
    addEventListener("resize", updateActiveFeatureHeight)
    return () => {
      removeEventListener("resize", updateActiveFeatureHeight)
    }
  }, [])

  useEffect(() => {
    if (!windowExists) return
    updateActiveFeatureHeight()
  }, [activeFeatureIndex])

  const updateActiveFeatureHeight = () => {
    const activeFeatureEl = document
      .getElementById(
        `${featureTabs[activeFeatureIndex]?.name}-${activeFeatureIndex}`
      )
      ?.getBoundingClientRect()
    activeFeatureEl?.height && setActiveFeatureHeight(activeFeatureEl.height)
  }

  return (
    <section
      css={[
        tw`col-span-2 px-6 w-full bg-gradient-to-b bg-mystic to-white py-12`,
        tw`md:(px-8)`,
        tw`lg:(mx-auto px-4)`,
      ]}
      aria-label="Hybrid Advantage"
    >
      {sectionTitle && (
        <div
          css={[
            tw`justify-self-center text-3xl font-light tracking-widest mb-10 text-center`,
            tw`md:(text-4xl)`,
          ]}
        >
          {parseDisclaimerBlocks(sectionTitle, selection =>
            dispatch(toggleDisclaimersModal(selection))
          )}
        </div>
      )}
      {subTitle && (
        <div
          css={[
            tw`justify-self-center text-lg font-light tracking-widest mb-10 text-center max-w-xl mx-auto`,
            tw`md:(text-xl)`,
          ]}
        >
          {subTitle}
        </div>
      )}
      <div
        css={[
          tw`overflow-auto overflow-x-scroll z-30 max-w-7xl mx-auto scrollbar-hide my-12`,
          tw`md:(z-10)`,
        ]}
      >
        <div css={[tw`flex flex-row gap-12 md:justify-center`]}>
          {featureTabs?.map((feature: VideoFeatureTabType, i) => {
            return (
              <button
                css={[
                  tw`text-xl text-center whitespace-nowrap font-light`,
                  tw`md:(text-2xl)`,
                  featureTabs[activeFeatureIndex]?.name === feature?.name
                    ? tw`font-semibold text-black text-opacity-100`
                    : tw`text-black text-opacity-50 xl:(text-opacity-100)`,
                ]}
                value={i}
                onClick={() => setActiveFeatureIndex(i)}
              >
                {feature.name}
              </button>
            )
          })}
        </div>
      </div>
      <div
        css={[
          tw`relative w-full`,
          tw`md:(max-w-7xl mx-auto)`,
          activeFeatureHeight && `height: ${activeFeatureHeight}px;`,
        ]}
      >
        {featureTabs?.map((feature, i) => (
          <VideoFeatureTab
            {...feature}
            active={activeFeatureIndex === i}
            id={`${feature?.name}-${i}`}
          />
        ))}
      </div>
    </section>
  )
}

export default VideoFeatureTabsSection
