import tw from "twin.macro"
import { VideoWithTextProps } from "./VideoWithText.d"
import { useContext, useEffect, useRef, useState } from "react"
import { DisclaimersContext } from "../../../contexts/Disclaimers"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { Image } from "../../atoms/Image"
import Play from "../../atoms/Icon/Play"
import { toggleDisclaimersModal } from "../../../contexts/Disclaimers/actions"
import { parseDisclaimerBlocks } from "../../../helpers"

const VideoWithText: React.FC<VideoWithTextProps> = ({
  heading,
  posterImage,
  body,
  videoPosition = "left",
  video_s3,
}) => {
  const [state, dispatch] = useContext(DisclaimersContext)
  const videoS3El = useRef(null)
  const { trackTealEvent } = useTealiumEvent()
  const [showImageOverlay, setShowImageOverlay] = useState(true)
  const [videoStatus, setVideoStatus] = useState("")

  // Tealium for video events using S3 hosted videos
  const percentWatched = () => {
    const percentage = Math.round(
      (videoS3El?.current.currentTime / videoS3El?.current?.duration) * 100
    )

    return percentage
  }
  const tealVideoEvent = (action: string, time?: string) => {
    const timeOnPause = `${percentWatched()}:${Math.round(
      videoS3El?.current?.currentTime
    )}`

    trackTealEvent({
      tealium_event: "video_event",
      video_type: `${`${heading}-${video_s3?.asset?._id}`}|${action}|${
        time ? time : timeOnPause
      }`,
    })
  }
  return (
    <div css={[tw`bg-mystic px-5 py-10 lg:p-20`]}>
      <div
        css={[tw`max-w-7xl grid grid-cols-1 gap-20 mx-auto xl:(grid-cols-2)`]}
      >
        <div
          css={[
            tw`flex flex-col place-content-center col-span-1 relative cursor-pointer w-full relative`,
            videoPosition === "right" && tw`order-2 `,
          ]}
        >
          <div
            onClick={() => {
              setShowImageOverlay(false)
              videoS3El?.current?.play()
              setVideoStatus("play")
            }}
          >
            <video
              ref={videoS3El}
              id={video_s3?.asset?._id}
              controls={true}
              title={heading}
              src={`${process.env.GATSBY_ASSET_URL}${video_s3?.asset?.s3?.key}`}
              onPlay={() => {
                //only fire on initial play
                videoS3El?.current?.currentTime == 0 &&
                  tealVideoEvent("start", "0:0")
                setVideoStatus("play")
              }}
              onPause={() => {
                //don't fire on end of video
                videoS3El?.current?.currentTime !==
                  videoS3El?.current?.duration && tealVideoEvent("pause")
                videoS3El?.current?.currentTime !==
                  videoS3El?.current?.duration && setVideoStatus("pause")
              }}
              onEnded={() => {
                tealVideoEvent(
                  "ended",
                  `100:${Math.round(videoS3El?.current?.duration)}`
                )
                setVideoStatus("ended")
              }}
              css={[
                tw`rounded-2xl object-contain mx-auto w-full h-auto bg-black`,
              ]}
            ></video>
            {posterImage && showImageOverlay && (
              <div css={[tw``]}>
                <Image
                  imageData={posterImage}
                  // imageData={posterImage.image}
                  // src={posterImage}
                  css={[
                    tw`absolute top-0 left-0 rounded-2xl object-cover mx-auto w-full h-auto p-0`,
                  ]}
                />
                <Play
                  css={tw`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2`}
                />
              </div>
            )}
          </div>
        </div>
        <div
          css={[
            tw`col-span-1 h-full flex flex-col gap-10 place-content-center text-center lg:(text-left)`,
            videoPosition === "right" && tw`order-1`,
          ]}
        >
          <h2 css={[tw`text-3xl font-light tracking-widest md:(text-4xl)`]}>
            {parseDisclaimerBlocks(heading, code =>
              dispatch(toggleDisclaimersModal(code))
            )}
          </h2>
          <p>
            {parseDisclaimerBlocks(body, code =>
              dispatch(toggleDisclaimersModal(code))
            )}
          </p>
        </div>
      </div>
    </div>
  )
}

export default VideoWithText
